import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { useState } from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import {
  Dropdown,
  DropdownOptionProps,
  Counter,
  CounterSize,
  DropdownOptionTextOverflow,
  DropdownTheme,
} from 'wix-ui-tpa';
import { BorderStyle } from '../../../../types/types';
import settingsParams from '../../settingsParams';
import { PriceOptionDropdownDataHooks } from './dataHooks';
import { classes, st } from './PriceOptionDropdown.st.css';

export interface PriceOptionDropdownProps {
  options: PriceChoice[];
  maxParticipants: number;
  label: string;
  onDropdownClose?: (
    priceOptionData: PriceOptionNumberOfParticipants[],
  ) => void;
  onChange?: (priceOptionData: PriceOptionNumberOfParticipants[]) => void;
  showPrice?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export interface PriceOptionNumberOfParticipants {
  choiceId: string;
  title: string;
  numberOfParticipants: number;
}

export interface PriceChoice {
  choiceId: string;
  title: string;
  price?: string;
  numberOfParticipantsInitialValue?: number;
}

const PriceOptionDropdown: React.FC<PriceOptionDropdownProps> = ({
  options,
  maxParticipants,
  label,
  onDropdownClose,
  onChange,
  showPrice,
  error,
  disabled,
}) => {
  const dropdownOnChange = (selectedOption: DropdownOptionProps) => {
    const selectedOptionIndex = options.findIndex(
      (option: PriceChoice) => option.choiceId === selectedOption.id,
    )!;
    setCounterValueAtIndex({
      index: selectedOptionIndex,
      value: 1,
      resetOtherCounters: true,
    });
  };

  const priceOptionToPriceOptionNumberOfParticipantsMapper = (
    priceOptions: PriceChoice[],
    useInitialValues?: boolean,
  ): PriceOptionNumberOfParticipants[] => {
    return priceOptions.map((option) => ({
      choiceId: option.choiceId,
      title: option.title,
      numberOfParticipants: useInitialValues
        ? option.numberOfParticipantsInitialValue || 0
        : 0,
    }));
  };

  const [counterValues, setCounterValues] = useState<
    PriceOptionNumberOfParticipants[]
  >(priceOptionToPriceOptionNumberOfParticipantsMapper(options, true));

  const { t } = useTranslation();
  const settings = useSettings();
  const theme =
    settings.get(settingsParams.fieldsBorderStyle) === BorderStyle.UNDERLINE
      ? DropdownTheme.Line
      : DropdownTheme.Box;

  const onExpandedChange = (isExpanded: boolean) => {
    const isCountersDropdown = maxParticipants > 1;
    if (!isExpanded && isCountersDropdown) {
      onDropdownClose?.(counterValues);
    }
  };

  const setCounterValueAtIndex = ({
    index,
    value,
    resetOtherCounters,
  }: {
    index: number;
    value: number;
    resetOtherCounters?: boolean;
  }) => {
    if (value < 0) {
      value = 0;
    }

    setCounterValues(
      (
        prevState: PriceOptionNumberOfParticipants[],
      ): PriceOptionNumberOfParticipants[] => {
        const nextState = resetOtherCounters
          ? priceOptionToPriceOptionNumberOfParticipantsMapper(options)
          : prevState;
        nextState[index] = { ...prevState[index], numberOfParticipants: value };
        const counterSumAfterChange = getSumOfCounters(nextState);

        if (counterSumAfterChange > maxParticipants) {
          nextState[index].numberOfParticipants =
            value - (counterSumAfterChange - maxParticipants);
        }
        onChange?.(nextState);
        if (maxParticipants === 1) {
          onDropdownClose?.(nextState);
        }
        return [...nextState];
      },
    );
  };

  const getPlaceholderString = () => {
    const result = counterValues
      .filter((counter) => counter.numberOfParticipants > 0)
      .map((counter) => `${counter.numberOfParticipants} ${counter.title}`);
    return (
      result.join(', ') ||
      t('app.dynamic-price.select-price-option.placeholder.text')
    );
  };

  const getSumOfCounters = (
    counterArray?: PriceOptionNumberOfParticipants[],
  ): number => {
    return (counterArray || counterValues).reduce(
      (acc, next) => acc + next.numberOfParticipants,
      0,
    );
  };

  const addSubtitle = (mappedOptions: DropdownOptionProps[]) => {
    mappedOptions.unshift({
      id: '-1',
      value: t('app.booking-details.price-option.subtitle', {
        maxParticipants,
      }),
      isSelectable: false,
      isSectionTitle: true,
    });
  };

  const getDropdownOptions = () => {
    const mappedOptions = options.map(priceOptionToDropdownOptionMapper());
    if (maxParticipants > 1) {
      addSubtitle(mappedOptions);
    }
    return mappedOptions;
  };

  const priceOptionToDropdownOptionMapper = () => (
    option: PriceChoice,
    i: number,
  ): DropdownOptionProps => {
    const openSpotsLeft = maxParticipants - getSumOfCounters();
    const counterMax = counterValues[i].numberOfParticipants + openSpotsLeft;
    const dropdownOptionValue =
      option.title + (option.price && showPrice ? ` - ${option.price}` : '');
    return {
      id: `${option.choiceId}`,
      value: dropdownOptionValue,
      isSelectable: maxParticipants === 1,
      textOverflow: DropdownOptionTextOverflow.ellipsis,
      suffix:
        maxParticipants > 1 ? (
          <div data-hook={PriceOptionDropdownDataHooks.COUNTER_WRAPPER}>
            <Counter
              data-hook={PriceOptionDropdownDataHooks.COUNTER}
              className={classes.counter}
              onChange={(val: string) => {
                setCounterValueAtIndex({
                  index: i,
                  value: parseInt(val, 10),
                });
              }}
              incrementAriaLabel={`${t(
                'app.booking-details.price-option.aria-increase',
              )} ${option.title}`}
              decrementAriaLabel={`${t(
                'app.booking-details.price-option.aria-decrease',
              )} ${option.title}`}
              inputAriaLabel={t(
                'app.booking-details.price-option.aria-counter-input-label',
              )}
              value={counterValues[i].numberOfParticipants}
              size={CounterSize.xSmall}
              min={0}
              max={counterMax}
              disabled={counterMax === 0}
            />
          </div>
        ) : undefined,
    };
  };

  return (
    <Dropdown
      className={st(classes.root, { theme })}
      label={label}
      upgrade
      theme={theme}
      data-hook={PriceOptionDropdownDataHooks.DROPDOWN}
      placeholder={getPlaceholderString()}
      options={getDropdownOptions()}
      onExpandedChange={onExpandedChange}
      onChange={dropdownOnChange}
      initialSelectedId={
        maxParticipants === 1
          ? counterValues?.find(
              (priceOptionData: PriceOptionNumberOfParticipants) =>
                priceOptionData.numberOfParticipants === 1,
            )?.choiceId
          : undefined
      }
      error={error}
      errorMessage={t('app.form-inputs.validation-errors.required-field')}
      newErrorMessage
      disabled={disabled}
    />
  );
};

export default PriceOptionDropdown;
